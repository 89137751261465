import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
// import { init } from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

// // local import
import "./style.scss";

const Contact = () => {
  const [isVisible, setVisible] = useState(false);

  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);

  const { register, errors, handleSubmit, reset } = useForm();

  const toastifySuccess = () => {
    toast("Form sent!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const onSubmit = async (data) => {
    const serviceID = "service_hlzvupn";
    const templateID = "template_fs3521l";
    const userID = "user_jwQNE9kBguupZTfg7OI6z";
    // Send form email
    try {
      const templateParams = {
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
      };

      await emailjs.send(serviceID, templateID, templateParams, userID);

      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      <div className="msgMe">
        <h2>Want to work together?</h2>
      </div>

      <div className="ContactForm">
        <div className="containerForm">
          <div className="row">
            <div className="text-center">
              <div className="contactForm">
                <form
                  id="contact-form"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <div className="row formRow">
                    <div className="col1">
                      <input
                        type="text"
                        name="name"
                        ref={register({
                          required: {
                            value: true,
                            message: "Please enter your name",
                          },
                          maxLength: {
                            value: 30,
                            message: "Please use 30 characters or less",
                          },
                        })}
                        className="form-control formInput"
                        placeholder="Name"
                      ></input>
                      {errors.name && (
                        <span className="errorMessage">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                    <div className="col2">
                      <input
                        type="email"
                        name="email"
                        ref={register({
                          required: true,
                          pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                        className="form-control formInput"
                        placeholder="Email address"
                      ></input>
                      {errors.email && (
                        <span className="errorMessage">
                          Please enter a valid email address
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row formRow">
                    <div className="col">
                      <input
                        type="text"
                        name="subject"
                        ref={register({
                          required: {
                            value: true,
                            message: "Please enter a subject",
                          },
                          maxLength: {
                            value: 75,
                            message: "Subject cannot exceed 75 characters",
                          },
                        })}
                        className="form-control formInput"
                        placeholder="Subject"
                      ></input>
                      {errors.subject && (
                        <span className="errorMessage">
                          {errors.subject.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row formRow">
                    <div className="col">
                      <textarea
                        rows={3}
                        name="message"
                        ref={register({
                          required: true,
                        })}
                        className="msgArea"
                        placeholder="Message"
                      ></textarea>
                      {errors.message && (
                        <span className="errorMessage">
                          Please enter a message
                        </span>
                      )}
                    </div>
                  </div>
                  <button
                    className="submit-btn"
                    type="submit"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    Submit
                  </button>
                </form>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
