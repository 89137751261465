import React, { useState } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Triangle } from "react-loader-spinner";

// local import
import "./App.scss";
import Hero from "./components/HeroHeader";
import Menu from "./components/Menu";
import About from "./components/About";
import Project from "./components/Project";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

const App = () => {
  const [loading, setloading] = useState(undefined);
  const [completed, setcompleted] = useState(undefined);

  setTimeout(() => {
    setloading(true);

    setTimeout(() => {
      setcompleted(true);
    }, 50);
  }, 2000);

  return (
    <>
      {!completed ? (
        <div>
          {!loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Triangle
                color="#ff8c00"
                strokeWidth={5}
                height={200}
                width={200}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="App">
          <Hero />
          <div>
            <Menu />
            <div>
              <Switch>
                <Route path="/Contact">
                  <Contact />
                </Route>
                <Route path="/Project">
                  <Project />
                </Route>
                <Route path="/About">
                  <About />
                </Route>
                <Route exact path="/">
                  <About />
                </Route>

                <l>
                  <Link to="/About">About</Link>
                </l>
                <l>
                  <Link to="/Project">Project</Link>
                </l>
                <l>
                  <Link to="/Contact">Contact</Link>
                </l>
              </Switch>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default App;
