import React, { useState } from "react";
import { Oval } from "react-loader-spinner";

// local import
import "./style.scss";

const Card = ({ content }) => {
  const [isLoading, setLoading] = useState(false);

  const onImageLoading = () => {
    setLoading(true);
  };

  const onLoadingError = () => {
    console.log("Error while loading image");
  };

  return (
    <div className="containerCard">
      <div className="boxCard">
        {!isLoading && (
          <div className="spinner">
            <Oval
              color="#ff8c00"
              secondaryColor="#ff8c00"
              strokeWidth={5}
              height={80}
              width={80}
            />
          </div>
        )}
        <img
          src={content.img}
          onLoad={onImageLoading}
          onError={onLoadingError}
          alt={content.title}
          className={isLoading ? "visible, imgCard" : "invisible"}
        />

        <div className="contentBox">
          <h2 className="hTitle">{content.title}</h2>
          <p>{content.description}</p>
          <a
            href={content.link}
            className="aLink"
            target="_blank"
            rel="noreferrer noopener"
          >
            Click for more
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;
