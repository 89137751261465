import React from "react";
import { Link } from "react-router-dom";

// local import
import "./style.scss";

const Menu = () => {
  const home = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="menu">
      <ul>
        <li data-text="Home">
          <Link to="#" onClick={home}>
            Home
          </Link>
        </li>
        <li data-text="About">
          <Link to="/About">About</Link>
        </li>
        <li data-text="Projects">
          <Link to="/Project">Projects</Link>
        </li>
        <li data-text="Contact">
          <Link to="/Contact">Contact</Link>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
