// local import
import "./style.scss";
import infoot from "../../assets/infoot.png";
import gitfoot from "../../assets/gitfoot.png";

const Footer = (props) => {
  return (
    <div className="footerDiv">
      <div className="footericon">
        <a className="aIcon" href="https://www.linkedin.com/in/antgdev/">
          <img className="imgIcon" alt="linicon" src={infoot} />
        </a>
        <a className="aIcon" href="https://github.com/antg01">
          <img className="imgIcon" alt="giticon" src={gitfoot} />
        </a>
      </div>

      <div className="copy">Copyright AntoineGe ©2021</div>
    </div>
  );
};

export default Footer;
