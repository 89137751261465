import React, { useEffect, useState, useRef } from "react";

// local import
import "./style.scss";
import Sometext from "../../utils/text.js";
import me from "../../assets/me.jpeg";
import shell1 from "../../assets/shell.png";
import html from "../../assets/html.png";
import css from "../../assets/css.png";
import js from "../../assets/js.png";
import react from "../../assets/react.png";
import node from "../../assets/node.png";
import sql from "../../assets/sql.png";
import ps from "../../assets/ps.png";
import figma from "../../assets/figma.png";
import git from "../../assets/git.png";
import angular from "../../assets/angular.png";
import mongo from "../../assets/mongo.png";
import sass from "../../assets/sass.png";

const About = (props) => {
  const [isVisible, setVisible] = useState(false);

  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);

  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      <div className="about">
        <div className="meDiv">
          <img src={me} alt="I" className="me" />
        </div>

        <div className="textWrapper">
          <h1>Who's this dude?</h1>
          <p className="text">{Sometext[0].text1}</p>
          <p className="text">{Sometext[0].text2}</p>
          <h3 className="textSet">My actual skill set:</h3>
        </div>
      </div>

      <div className="iconsDisplay">
        <img src={shell1} alt="shell" className="icons" />
        <img src={html} alt="html" className="icons" />
        <img src={css} alt="css" className="icons" />
        <img src={sass} alt="sass" className="icons" />
        <img src={js} alt="css" className="icons" />
        <img src={react} alt="react" className="icons" />
        <img src={angular} alt="angular" className="icons" />
      </div>
      <div className="iconsDisplay">
        <img src={node} alt="node" className="icons" />
        <img src={sql} alt="sql" className="icons" />
        <img src={mongo} alt="mongo" className="icons" />
        <img src={figma} alt="figma" className="icons" />
        <img src={ps} alt="ps" className="icons" />
        <img src={git} alt="git" className="icons" />
      </div>
    </div>
  );
};

export default About;
