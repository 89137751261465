import tie from "../assets/tie.png";
import sneakin from "../assets/sneakin.png";
import kronos from "../assets/kronos.png";
// import us from "../assets/us.jpg";
// import linkzy from "../assets/linkzy.jpg";
import rn from "../assets/reactnative.png";

const CardContent = [
  {
    id: 1,
    title: "Tech it easy",
    img: tie,
    description:
      "It is a project for a start-up that needed a web media platform. The website proposes content about the digital world [...]",
    techno: " [Html/CSS, Vanilla Js]",
    link: "https://github.com/antg01/tech-it-easy",
    isLoading: false,
  },
  {
    id: 2,
    title: "Sneak In",
    img: sneakin,
    description:
      "A web app for Sneaker aficionados that display current data from an API. The app display was made for [...]",
    techno: "[React]",
    link: "https://github.com/antg01/sneakIn",
    isLoading: false,
  },
  {
    id: 3,
    title: "Kronos BnB",
    img: kronos,
    description:
      "I did a 24h hour long hackathon for my bootcamp. The subject was 'Back from the Futur'... we did 1st place [...]",
    techno: "[React]",
    link: "https://github.com/antg01/kronos",
    isLoading: false,
  },
  {
    id: 4,
    title: "Us",
    img: rn,
    description:
      "The client wanted a mobile app for her expat community. The app allows to create and manage events and meet [...]",
    techno: "[React Native, Redux, Node/Express, MySql/Sequelize]",
    link: "https://github.com/antg01/UsProject",
    isLoading: false,
  },
  {
    id: 5,
    title: "Linkzy",
    img: rn,
    description:
      "Spielberg Consultant Agency and SD Worx organized a 48h long hackathon. The idea was to deliver a concept that [...]",
    techno: "[React Native, Redux, Node/Express, MySql/Sequelize]",
    link: "https://github.com/antg01/Linkzy",
    isLoading: false,
  },
];
export default CardContent;
