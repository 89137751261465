import React, { useEffect, useState, useRef } from "react";

// local import
import "./style.scss";
import Card from "../Card/Card";
import contents from "../../utils/CardContent.js";

const Project = (props) => {
  const [isVisible, setVisible] = useState(false);

  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);

  const maping = () => {
    return contents.map((content) => {
      return <Card key={content.id} content={content} />;
    });
  };

  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      <div className="cardsDisplay">
        <div className="cardsGrid"> {maping()}</div>
      </div>
    </div>
  );
};

export default Project;
