import { useEffect, useState } from "react";

// local import
import "./style.scss";
import bg from "../../assets/bg.jpg";
import moon from "../../assets/moon.png";
import mountain from "../../assets/mountain.png";
import road from "../../assets/road.png";

const Hero = () => {
  const [offset, setOffset] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const onImageLoading = () => {
    setLoading(true);
  };

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {!isLoading && (
        <div
          style={{
            background: "#0a2a43",
          }}
        ></div>
      )}
      <section className="section">
        <section className="Imgsection">
          <img
            src={bg}
            onLoad={onImageLoading}
            alt="night sky"
            className="nightSky"
            style={{
              transform: `translateY(${offset * 0.5}px)`,
            }}
          />
          <img
            src={moon}
            onLoad={onImageLoading}
            alt="moon"
            className="moon"
            style={{
              transform: `translateX(${offset * -0.5}px)`,
            }}
          />
          <img
            src={mountain}
            onLoad={onImageLoading}
            alt="mountains"
            className="mountains"
            style={{
              transform: `translateY(${offset * -0.15}px)`,
            }}
          />
          <img
            src={road}
            onLoad={onImageLoading}
            alt="road"
            className="road"
            style={{
              transform: `translateY(${offset * 0.15}px)`,
            }}
          />
          <h2
            className="meName"
            style={{
              transform: `translateY(${offset * 0.65}px)`,
            }}
          >
            Antoine Gerard
          </h2>
        </section>
      </section>
    </div>
  );
};

export default Hero;
